<template>
  <form v-if="!passwordChange" class="page-login content-boxed content-boxed-padding" @submit.prevent="resetEmail" autocomplete="on">
    <h3 class="bold small-top no-bottom center-text">{{lang[this.appLang].reset_password}}</h3>
    <p class="half-bottom small-top center-text color-black"></p>
    <div class="page-login-field half-top color-black">
	  <i class="fa" style="padding-top: 8px; margin-left: 10px;">
		<img src="https://cdn.tapni.co/icons/mail.png" class="fa responsive-image" style="width: 80%;">
	  </i>
	  <input id="emailInput" v-model="email" @keydown.space.prevent type="email" :placeholder="lang[this.appLang].email" required>
	  <em></em>
	</div>
    <button type="submit" class="button google-button bg-tapni-grey button-90 button-center uppercase bold full-top">
      <span v-if="!submitted" class="uppercase">{{lang[this.appLang].reset_password}}</span>
      <i v-else class="fa fa-circle-o-notch fa-spin" style="margin: 15px auto 0;width: 100%; text-align: center; font-size: 20px;"></i>
    </button>
    <div class="decoration decoration-lines-thin no-bottom half-top"></div>
	<p class="close-text center-text half-top color-black">
	  <span v-html="lang[this.appLang].reset_password_p1"></span><a :href="'mailto:support@tapni.co?subject=' + lang[this.appLang].password_reset_t"> support@tapni.co</a>
	</p>
    <div class="decoration decoration-lines-thin no-bottom half-top"></div>

	<div class="page-login-links">
	  <router-link class="create float-right" to="/verify">{{lang[this.appLang].verify_account}}</router-link>
	  <router-link class="forgot float-left" to="/login">{{lang[this.appLang].sign_in}}</router-link>
	  <div class="clear"></div>
	</div>
  </form>
  <form v-else class="page-login content-boxed content-boxed-padding" @submit.prevent="changePw">
    <h3 class="bold small-top no-bottom center-text">{{lang[this.appLang].password_change}}</h3>
    <p class="half-bottom small-top center-text color-black">{{lang[this.appLang].password_new}}</p>
    <div class="page-login-field half-top">
	  <i class="fa" style="padding-top: 2px;">
		  <img src="https://cdn.tapni.co/icons/padlock.png" class="fa responsive-image" style="width: 50%;">
	  </i>
      <input v-model="password" :type="revealPassword ? 'text' : 'password'" :placeholder="lang[this.appLang].password" required>
	  <em @click="revealPassword = !revealPassword">
		 <img v-show="!revealPassword" src="https://cdn.tapni.co/icons/reveal-password.png" class="fa responsive-image password-reveal-icon">
		 <img v-show="revealPassword" src="https://cdn.tapni.co/icons/hide-password.png" class="fa responsive-image password-reveal-icon">
	  </em>
    </div>
    <div class="page-login-field half-top">
	  <i class="fa" style="padding-top: 2px;">
		  <img src="https://cdn.tapni.co/icons/padlock.png" class="fa responsive-image" style="width: 50%;">
	  </i>
      <input v-model="passwordRepeat" :type="revealPassword2 ? 'text' : 'password'" :placeholder="lang[this.appLang].password_repeat" required>
	  <em @click="revealPassword2 = !revealPassword2">
		  <img v-show="!revealPassword2" src="https://cdn.tapni.co/icons/reveal-password.png" class="fa responsive-image password-reveal-icon">
		  <img v-show="revealPassword2" src="https://cdn.tapni.co/icons/hide-password.png" class="fa responsive-image password-reveal-icon">
	  </em>
    </div>
    <button type="submit" class="button google-button bg-tapni-grey button-90 button-center uppercase bold full-top">
      <span v-if="!submitted" class="uppercase">{{lang[this.appLang].password_change}}</span>
      <i v-else class="fa fa-circle-o-notch fa-spin" style="margin: 15px auto 0;width: 100%; text-align: center; font-size: 20px;"></i>
    </button>

	<div class="decoration decoration-lines-thin no-bottom half-top"></div>
	<p class="close-text center-text half-top color-black">
	  <span v-html="lang[this.appLang].reset_password_p2"></span> <a :href="'mailto:support@tapni.co?subject=' + lang[this.appLang].password_reset_t"> support@tapni.co</a>
	</p>
  </form>
</template>

<script>
/* eslint-disable */
import { mapActions } from 'vuex'
export default {
  name: 'AuthReset',
  data () {
    return {
      loading: true,
      submitted: false,
      passwordChange: false,
      email: '',
      password: '',
      passwordRepeat: '',
      code: '',
      captcha: '',
      revealPassword: false,
      revealPassword2: false,
    }
  },
  mounted () {
    if (this.storage.verifyEmail) {
      this.email = this.storage.verifyEmail
    }
    if (this.$route.query.c) {
      this.code = this.$route.query.c;
      this.passwordChange = true
    }
    if (this.route.query.e) {
      this.email = this.route.query.e
    }

    this.$store.commit('setInitialize', true);
    this.$store.commit('setLoading', false);
  },
  methods: {
    ...mapActions([
      'reset',
      'changePassword',
    ]),
    resetEmail() {
      // if (this.captcha === '') return this.errorHandler({ message: 'Please select recaptcha' });

      if (this.submitted) return
      this.submitted = true

	  this.email = this.email.trim()

      let data = {
        email: this.email,
        captcha: this.captcha,
      };

      this.reset(data).then(() => {
        this.submitted = false
        this.$router.push('/verify?e=' + this.email + '#reset')
      })
    },
    changePw() {
      // if (this.captcha === '') return this._vm.errorHandler({ message: 'Please select recaptcha' });
      if (this.code.length !== 6) return this.errorSnack(this.lang[this.appLang].invalid_code);
      if (this.password !== this.passwordRepeat) return this.errorSnack(this.lang[this.appLang].password_not_match);

      if (this.submitted) return
      this.submitted = true

	  this.email = this.email.trim()
	  this.code = this.code.trim()

      let data = {
        email: this.email,
        code: this.code,
        password: this.password,
        passwordRepeat: this.passwordRepeat,
      };

      this.changePassword(data).then((success) => {
        this.submitted = false
        if (success === true) {
          this.$router.push('/login');
          this.successSnack(this.lang[this.appLang].password_reseted_success);
        }
      })
    },
  },
  watch: {
    email(nv) { this.storage.verifyEmail = nv }
  }
}
</script>

<style scoped>
  #emailInput {
	  text-align: center;
	  padding-right: 15%;
	  font-size: 17px;
  }
</style>
